.landing .next-section .title.in-view::after {
    width: 0%;
}

.landing .next-section .title::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    height: calc(100% - 3rem);
    transition: .5s ease-in-out;
    transform: translateY(-50%);
    backdrop-filter: blur(5px);
    filter: blur(5px);
    width: 100%;
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, .15), rgba(255, 255, 255, .025));
    background-color: rgb(56, 86, 255, .05);
}


.landing .next-section .title.in-view div {
    opacity: 1;
}

.landing .next-section > .p {
    max-width: 40rem;
    font-size: .85rem;
    padding: 0 0 2rem 2.5rem;
    color: var(--white);
    opacity: .6;
}

.landing .next-section .title div {
    font-size: 2.75rem;
    transition: .5s ease-in-out;
    transition-delay: .15s;
    opacity: 0;
}

.landing .next-section .title {
    padding: 3rem;
    max-width: fit-content;
    position: relative;
    color: var(--white);
    text-transform: capitalize;
}

/* cards general default */
.landing .next-section .services section {
    position: relative;
}

.landing .next-section .services section * {
    transition: .5s ease-in-out;
    opacity: 0;
    transition-delay: .15s;
}

.landing .next-section .services section.in-view * {
    opacity: 1;
}

.landing .next-section .services section::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    height: 100%;
    backdrop-filter: blur(5px);
    transition: .5s ease-in-out;
    transform: translateY(-50%);
    width: 100%;
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, .15), rgba(255, 255, 255, .025));
    background-color: rgb(56, 86, 255, .05);
}

.landing .next-section section.in-view::after {
    width: 0%;
}

.landing .next-section.how .hanging-img.l {
    left: 13%;
}

.landing .next-section.how .hanging-img.r {
    right: 0%;
}

.landing .next-section.how .hanging-img {
    position: absolute;
    top: 55%;
    transform: translateY(-50%) translateX(-50%);
}
.landing .next-section.how .hanging-img.l img {
    animation-delay: 5s;
    animation-duration: 7s;
}

.landing .next-section.how .hanging-img.r img {
    animation-delay: 5s;
    animation-duration: 5s;
}

.landing .next-section.how .hanging-img img{
    animation: img-bouncing linear infinite;
    opacity: .6;
    height: 10rem;
}

.landing .next-section.how {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 6rem 4rem;
    min-height: auto;
}

.landing .next-section .title-middle {
    width: 100%;
    text-align: center;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing .next-section .title-middle .btnx {
    font-size: unset;
    margin: 2rem 0;
    opacity: 1;
}

.landing .next-section .title-middle .btnx:hover {
    background-color: var(--main-blue);
    color: var(--white);
}

.landing .next-section .title-middle .btnx:hover::after {
    border-color: var(--main-blue);
}

.landing .next-section .title-middle div.in-view {
    transform: translateY(0);
    opacity: 1;
}

.landing .next-section .title-middle div {
    font-size: 3.25rem;
    transition: .5s cubic-bezier(0.39, 0.575, 0.565, 1);
    transform: translateY(20px);
    transition-delay: .05s;
    opacity: 0;
    position: relative;
    font-weight: 500;
    max-width: 80%;
}

.landing .next-section .title-middle div:not(.btnx)::selection {
    color: var(--main-blue);
    background: var(--white);
}

.landing .next-section .title-middle div:not(.btnx)::after {
    content: "How it works";
    font-size: 0.85rem;
    position: absolute;
    top: 0;
    padding: 2.5px 10px;
    border-radius: 5rem;
    background-color: var(--main-blue);
    right: 0;
    transform: rotate(25deg) translateY(10px) translateX(10px);
}

.landing .next-section .title-middle span.in-view {
    transform: translateY(0);
    opacity: .6;
}

.landing .next-section .title-middle span {
    font-size: .85rem;
    transform: translateY(20px);
    transition: .5s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-delay: .25s;
    opacity: 0;
    max-width: 50%;
    padding: 1rem;
}

.landing .next-section .how-bottom.in-view {
    opacity: 1;
    transform: translateY(0);
}

.landing .next-section .how-bottom {
    padding: 2rem 0;
    width: 100%;
    border-radius: .5rem;
    border: 1px solid rgba(255, 255, 255, .15);
    background-image: linear-gradient(to left, rgba(255, 255, 255, .025), rgba(255, 255, 255, .015));
    backdrop-filter: blur(10px);
    position: relative;
    z-index: 50;
    margin-top: 10rem;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    color: var(--white);
    transform: translateY(20px);
    transition: .5s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-delay: .15s;
    opacity: 0;
}

.landing .next-section.mock section.img.in-view{
    transform: translateX(0);
    opacity: 1;
}

.landing .next-section.mock section.img{
    transform: translateX(10rem);
    opacity: 0;
    transition-delay: .15s;
    transition: var(--trans);
}

.landing .next-section.mock section:not(.img).in-view{
    transform: translateX(0);
    opacity: 1;
}

.landing .next-section.mock section:not(.img){
    transform: translateX(-10rem);
    opacity: 0;
    transition-delay: .25s;
    transition: var(--trans);
}